import React from "react"

import Layout from "../components/Layout"
import Contato from "../components/Contato"
import SEO from "../components/seo"

const ContatoPage = () => {
  return (
    <Layout>
      <SEO title="Contato" />
      <Contato title={true} />
    </Layout>
  )
}

export default ContatoPage
